/**
 * Diese Funktion extrahiert den Path aus einem Event.
 * Da es in Chrome und Firefox diesbezüglich Unterschiede gibt, wird
 * diese Funktion zur Verfügung gestellt.
 */
export function extractPath(event) {
    return event.path || (event.composedPath && event.composedPath());
}
export function getKeyboardKey(eventOrLetter) {
    return typeof eventOrLetter === "string" ? eventOrLetter : eventOrLetter.key;
}
export function isDigit(event) {
    const c = getKeyboardKey(event);
    return c >= "0" && c <= "9";
}
export function isLetter(eventOrLetter) {
    const key = getKeyboardKey(eventOrLetter).toLowerCase();
    return key.length === 1 && ((key >= "a" && key <= "z") || key == "ä" || key == "ö" || key == "ü");
}
export function isSpace(eventOrLetter) {
    return getKeyboardKey(eventOrLetter) === " ";
}
export function isEnter(eventOrLetter) {
    return getKeyboardKey(eventOrLetter) === "Enter";
}
export class DOMEventHelper {
    /**
     * A class which manages registration and unregistration to a DOM-event of an element.
     *
     * @param context The `this` context which should be used within the handle function.
     * @param eventName The name of the event.
     * @param handle The events and their corresponding handle functions.
     */
    constructor(eventName, context, handle) {
        this.eventName = eventName;
        this.context = context;
        this.handle = handle;
        this.handle = handle === null || handle === void 0 ? void 0 : handle.bind(context);
    }
    on(element, options) {
        if (this.context == null || this.handle == null || element === this.registeredOnElement) {
            return;
        }
        this.disconnect();
        this.registeredOnElement = element;
        element === null || element === void 0 ? void 0 : element.addEventListener(this.eventName, this.handle, options);
    }
    disconnect() {
        var _a;
        (_a = this.registeredOnElement) === null || _a === void 0 ? void 0 : _a.removeEventListener(this.eventName, this.handle);
        this.registeredOnElement = null;
    }
}
export class TransitionStartHelper {
    /**
     * A class which manages registration and unregistration to the currently JSX unsupported "onTransitionStart" event.
     *
     * @param context The `this` context which should be used within the handle function.
     * @param handle The events and their corresponding handle functions.
     */
    constructor(context, handle) {
        this.context = context;
        this.handle = handle;
        this.handle = handle === null || handle === void 0 ? void 0 : handle.bind(context);
    }
    on(element) {
        if (this.context == null || this.handle == null || element === this.registeredOnElement) {
            return;
        }
        this.disconnect();
        this.registeredOnElement = element;
        element === null || element === void 0 ? void 0 : element.addEventListener(TRANSITION_START_EVENT_NAME, this.handle);
    }
    disconnect() {
        var _a;
        (_a = this.registeredOnElement) === null || _a === void 0 ? void 0 : _a.removeEventListener(TRANSITION_START_EVENT_NAME, this.handle);
        this.registeredOnElement = null;
    }
}
export function addKeyToEvent(e, key, value = true) {
    e[key] = value;
}
export function getKeyFromEvent(e, key) {
    return e[key];
}
const TRANSITION_START_EVENT_NAME = "transitionstart";
